import React from "react"
import { graphql } from "gatsby"

import LayoutV2 from "../components/v2/layout"
import SEO from "../components/seo"

import JobPortalPage from "./per-page/jobs"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title

    const {template} = this.props.data.markdownRemark.frontmatter;

    let PageBody = <></>;

    switch(template) {
        case "jobs" : PageBody = <JobPortalPage/>
    }

    return (
      <LayoutV2 location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} keywords={post.frontmatter.seo.keywords}/>
        {PageBody}
      </LayoutV2>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      frontmatter {
        title
        seo {
            keywords
        }
        navigation {
            headerActive
            showFooter
        }
        template
      }
    }
  }
`

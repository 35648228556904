import React from "react"
import { graphql, StaticQuery, Link} from "gatsby"
import { Section, PLink, PLinkLocal, EmptyComponent} from "../../components/partials/base"

import renderHTML from 'react-render-html';

import {MdLocationOn} from "react-icons/md"

class JobPortalPage extends React.Component {
    constructor (props) {
        super (props);

        const PER_PAGE = 6, NUMBER_OF_PAGES = Math.ceil(this.props.data.allJobNode.edges.length/PER_PAGE);
        this.state = {
            currentPage : 1,
            postsPerPage : PER_PAGE,
            numberOfPages : NUMBER_OF_PAGES,
            startIndex: 0,
        };

        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
    }

    nextPage = event => { // Older
        event.preventDefault();

        const {currentPage, startIndex, postsPerPage} = this.state;

        this.refs.jobListDiv.classList.remove("fadeIn");
        this.refs.jobListDiv.classList.add("animated", "fadeOut");

        setTimeout(() =>{
            this.refs.jobListDiv.classList.add("fadeIn");
            this.refs.jobListDiv.classList.remove("fadeOut");

            this.setState({
                currentPage : (currentPage + 1),
                startIndex : ((startIndex + postsPerPage))
            })
        }, 500);
    }

    prevPage = event => { // Newer
        event.preventDefault();

        const {currentPage, startIndex, postsPerPage} = this.state;

        const newStartIndex = (startIndex - postsPerPage)
        this.refs.jobListDiv.classList.remove("fadeIn");
        this.refs.jobListDiv.classList.add("animated", "fadeOut");

        setTimeout(() =>{
            this.refs.jobListDiv.classList.add("fadeIn");
            this.refs.jobListDiv.classList.remove("fadeOut");
            this.setState({
                currentPage : (currentPage - 1),
                startIndex : (newStartIndex < 0 ? 0 : newStartIndex)
            })
        }, 500);
    }

    render () {
        const jobs = this.props.data.allJobNode.edges, {currentPage, numberOfPages, startIndex, postsPerPage} = this.state;
        const endIndex = (startIndex + postsPerPage) - 1, showNext = currentPage < numberOfPages, showPrev = (currentPage > 1);

        return  <article className="post-content page-template no-image animated fadeIn">
        <div className="post-content-body">
            <div className="hero-title-div">
                <h1 className="hero-title-center">Job Portal</h1>
            </div>
            <div className="job-portal-div" ref={`jobListDiv`}>
                <Section sectionName="All Jobs">
                    <div className="job-list">
                        {   
                            jobs.map((item, index) =>{
                                let display = false;
                        
                                if((index >= startIndex) && (index <= endIndex)){
                                    display = true;
                                }

                                const {title, description, job_id, location, specialization, date_created} = item.node;
                                return display ? <PLink className="job-list-item" to={`/beta-job/${job_id}`} onClickFadeOut={true} ignoreScrollTop={true}>
                                    <div className="job-list-item--top-div">
                                        <div className="job-list-item--specialization">{specialization}</div> 
                                        <div className="job-list-item--location"><MdLocationOn/><span> {location} </span></div>
                                    </div>
                                    <div className="job-list-item--title">{title}</div>
                                    <div className="job-list-item--description">
                                        {renderHTML(description.substring(0, 200) + "...")}
                                    </div>
                                    <div className="job-list-item--date"><span>Posted {date_created}</span></div>
                                </PLink> : <EmptyComponent/>
                            })
                        }
                    </div>
                    <div className="job-list-pagination"> 
                        <div className="job-list-pagination-item job-list-pagination-item--prev">
                            {showPrev ? <PLinkLocal onClick={this.prevPage}>Previous</PLinkLocal> : <EmptyComponent/>}
                        </div>
                        <div className="job-list-pagination-item job-list-pagination-item--next">
                            {showNext ? <PLinkLocal onClick={this.nextPage}>Next</PLinkLocal> : <EmptyComponent/>}
                        </div>
                    </div>
                </Section>
            </div>
        </div>
    </article>
    }
}

const indexQuery = graphql`
    query JobPortalPageQuery {
        allJobNode {
            edges {
                node {
                    id
                    date_created(fromNow: true)
                    date_update
                    description
                    location
                    specialization
                    title
                    job_id
                }
            }
        }
    }`

export default props => (
    <StaticQuery
      query={indexQuery}
      render={data => (
        <JobPortalPage location={props.location} data={data} {...props} />
      )}
    />
  )